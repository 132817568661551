import About from "./pages/About";
import Contact from "./pages/Contact";
import Work from "./pages/Work";
import { Routes, Route } from "react-router-dom";
export default function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<About />} />
        <Route path="work" element={<Work />} />
        <Route path="Contact" element={<Contact />} />
      </Routes>
    </div>
  );
}
