import React from "react";
import { Link } from "react-router-dom";

function CustomHeader() {
  return (
    <div className="flex justify-between border-b-[1px] border-[#2a3033] bg-[#e8e8e4] py-2 ">
      <div>
        <img
          className="h-6 sm:h-16 mx-3 sm:mx-6 object-cover"
          alt="logo"
          src="/assets/logo.png"
        />
      </div>
      <div className="flex space-x-3 sm:space-x-6 mx-4 sm:mx-8 items-center">
        <Link to="/">
          <img
            className="h-3 sm:h-6 object-cover"
            alt="about"
            src="/assets/about.png"
          />
        </Link>
        <Link to="/work">
          <img
            className="h-3 sm:h-6 object-cover"
            alt="work"
            src="/assets/work.png"
          />
        </Link>
        <Link to="/contact">
          {" "}
          <img
            className="h-3 sm:h-6 object-cover"
            alt="contact"
            src="/assets/contact.png"
          />
        </Link>
      </div>
    </div>
  );
}

export default CustomHeader;
