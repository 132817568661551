import React from "react";
import { Fade } from "react-awesome-reveal";

function BodyWork() {
  return (
    <div className="flex flex-col">
      <div className="bg-[#e8e8e4]">
        <div>
          <Fade cascade>
            <img
              className="px-4 sm:px-14 py-4 sm:py-12"
              alt="firstpart"
              src="/assets/work1-min.png"
            />
            <div className="relative">
              <div className="bg-[#284830]">
                <img alt="secondpart" src="/assets/work2-min.png" />
              </div>
              <img className="" alt="thirdpart" src="/assets/work3-min.png" />
              <img
                className="absolute w-[40%] top-1/3 right-1/4"
                alt="top"
                src="/assets/top.png"
              />
            </div>

            <img className="" alt="4th" src="/assets/work4-min.png" />
            <img className="" alt="5th " src="/assets/work5-min.png" />
            <div className="bg-[#8b79b7]">
              <img className="" alt="6th" src="/assets/work6-min.png" />
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
}

export default BodyWork;
