import CustomFooter from "../components/CustomFooter";
import CustomHeader from "../components/CustomHeader";
import BodyContact from "../components/BodyContact";
export default function App() {
  return (
    <div>
      <CustomHeader />
      <BodyContact />
      <CustomFooter />
    </div>
  );
}
