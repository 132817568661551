import BodyAbout from "../components/BodyAbout";
import CustomFooter from "../components/CustomFooter";
import CustomHeader from "../components/CustomHeader";

export default function App() {
  return (
    <div>
      <CustomHeader />
      <BodyAbout />
      <CustomFooter />
    </div>
  );
}
