import React from "react";
import { Fade } from "react-awesome-reveal";
function BodyContact() {
  return (
    <div className="flex flex-col bg-[#e8e8e4]">
      <div className="m-5 sm:m-20 flex flex-col h-[88vh] sm:h-[80vh] justify-between">
        <img
          className="h-24 sm:h-44 w-24 sm:w-44"
          alt="topleft"
          src="/assets/tl.png"
        />
        <div className="relative flex justify-end sm:mr-60">
          <Fade cascade>
            <img
              className="h-28 sm:h-48 mb-4"
              alt="template"
              src="./assets/template.png"
            />

            <a href="mailto:mehrakan@gmail.com">
              <img
                className="h-3 sm:h-6 bottom-12 sm:bottom-16 right-0.5 sm:right-0 absolute"
                alt="email"
                src="./assets/email.png"
              />
            </a>

            <a href="https://instagram.com/mehrakan.sa">
              <img
                className="h-3 sm:h-6 absolute bottom-1  sm:-bottom-1 right-1"
                alt="instagram"
                src="./assets/insta.png"
              />
            </a>
          </Fade>
        </div>
      </div>
    </div>
  );
}

export default BodyContact;
