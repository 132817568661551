import React from "react";

function CustomFooter() {
  return (
    <div className="border-t-[1px] p-1 bg-[#e8e8e4] border-[#2a3033] flex justify-between">
      <img
        className="h-3 sm:h-6 mx-3 sm:mx-6 object-cover"
        alt="contact"
        src="/assets/creng.png"
      />
      <img
        className="h-3 sm:h-7 mx-3 sm:mx-6 object-cover"
        alt="contact"
        src="/assets/crara.png"
      />
    </div>
  );
}

export default CustomFooter;
