import React from "react";
import { Fade } from "react-awesome-reveal";

function BodyAbout() {
  return (
    <div className="flex flex-col bg-[#e8e8e4]">
      <Fade>
        <div className="bg-[#2a3033] h-[40vh] sm:h-[70vh] flex justify-between p-2 sm:p-0">
          <img
            className="h-8 sm:h-16 m-3 sm:m-10 object-cover"
            alt="welcome"
            src="/assets/w.png"
          />
          <img
            className="h-10 sm:h-16 m-4 sm:m-10 self-end object-cover"
            alt="slogan"
            src="/assets/slogan.png"
          />
        </div>
      </Fade>

      <div className="m-5 sm:m-20 ">
        <Fade cascade>
          <div>
            <img alt="firstpart" src="/assets/body1.png" />
          </div>
          <div className="flex flex-col">
            <img
              className="sm:-mb-64 z-50"
              alt="secondpart"
              src="/assets/body2.png"
            />

            <img
              className="h-full w-9/12 -mt-28 sm:-mt-96 mr-12 sm:mr-96 "
              alt="thirdpart"
              src="/assets/body3.png"
            />
          </div>
        </Fade>
      </div>
    </div>
  );
}

export default BodyAbout;
